export function getUserInitials(name: string) {
  return name.split(' ').reduce((acc, word) => {
    return acc + word.charAt(0);
  }, '');
}

export function getAvatarColor(name: string) {
  const COLORS = ['#FFBF3D', '#5E83BF', '#3D87FF', '#AA9261', '#A8D08D'];

  const nameLength = name.length;
  const colorIndex = nameLength % COLORS.length;

  return COLORS[colorIndex];
}
