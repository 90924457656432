<script setup lang="ts">
const { data: user } = useUser();

const avatarLabel = computed(() => {
  if (!user.value) {
    return '';
  }

  return getUserInitials(user.value.name);
});
</script>

<template>
  <PrimeAvatar
    v-if="user"
    class="shrink-0 font-normal text-paper-elevation-0"
    :label="avatarLabel"
    size="large"
    shape="circle"
    :style="`background-color: ${getAvatarColor(user.name)}`"
  />
</template>
